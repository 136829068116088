* {
  box-sizing: border-box;
}
body {
  background: url(https://images.unsplash.com/photo-1622667042273-e0e54442440a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
}

.container {

  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-width: 500px;
  width: 100%;
  margin: auto;
}

.container > form {
  width: 70%;
  border: 1px solid #dfdfdf;
  padding: 20px;
  border-radius: 5px;
  background: #fff;
}

input {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 25px;
  border-radius: 9%;
}

pre {
  width: 70%;
  color: #fff;
  font-size: larger;
}

button {
  background: #05b462 !important;
  padding: 5px;
  border-radius: 10px;
  width: 100%;
}

p {
  color: rgb(255, 0, 0);
}

.text {
  margin: 2px;
  padding: 5px;
}

span{
  color: rgb(0, 95, 236);
  cursor: pointer;
}